import React from "react"
import tw from "twin.macro"

import Content from "../components/pwcomps/content"

import { TransitionState } from "../helpers/DummyGatsbyPluginTransitionLink";
import { Spring, animated as a } from "@react-spring/web";
import Footer from "../components/footer";
import BreadCrumb from "../components/breadcrumb";
import FormularioDistribuidor from "../components/mini/formularioDistribuidor";
import Seo from "../components/seo";
import Mapa from "../components/3d/mapa";

const PageDondeComprar = props => {
  // onsole.log(props)
  const lang = props.pageContext.lang;
  const pageData = props.pageContext;
  // const homePageUrl = props.data.sitePage.context.ruta
  // const homePageTitle = props.data.sitePage.context.seo_title
  const { pageContext } = props;

  return (
    <>
      <Seo
        pageContext={pageContext}
      />
      <TransitionState>
        {({ transitionStatus, exit, entry }) => {
          const isMounting = ["entering", "entered"].includes(transitionStatus);
          const isExiting = transitionStatus === "exiting";
          return (
            <>
              <div css={tw`px-5 md: px-9 overflow-x-visible`}>
                <div
                  css={tw`max-w-screen-xl my-0 mx-auto px-0 font-obbody overflow-x-visible`}
                >
                  <article>
                    <div css={tw`relative`}>
                      <BreadCrumb
                        isMounting={isMounting}
                        pageContext={pageContext}
                      />
                      <Spring
                        to={{
                          transform: `translateY(${
                            isMounting ? "0rem" : isExiting ? "-2rem" : "10rem"
                          })`,
                          opacity: isMounting ? 1 : 0
                        }}
                      >
                        {styles => (
                          <a.div style={styles}>
                            <Mapa lang={lang} />

                            <FormularioDistribuidor nombreFormulario="Dónde comprar" />
                            <Content
                              contents={pageData.content}
                              lang={pageData.lang}
                              isMounting={isMounting}
                              isExiting={isExiting}
                              pageRuta={pageContext.ruta}
                            />
                          </a.div>
                        )}
                      </Spring>
                    </div>
                  </article>
                </div>
              </div>
              <Footer isMounting={isMounting} lang={lang} langSettings={props.pageContext.langSettings} />
            </>
          );
        }}
      </TransitionState>
    </>
  );
};

export default PageDondeComprar;
